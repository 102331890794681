'use client';

import React from 'react';

import type { Stage } from 'corgi-types';
import CommonDisclaimer from 'corgi/common-disclaimer/components/CommonDisclaimer';
import SmartBanner from 'corgi/common-smart-banner-dy/components/CommonSmartBanner';
import ErrorBoundary from 'corgi/common/components/ErrorBoundary';
import GlobalHead from 'corgi/global-head/components/GlobalHead';
import type { GlobalNavigationProps } from 'frontend-utils';

import { LogoutBanner } from '@components/common/fragments/AutoLogoutBanner';
import Footer from '@components/common/fragments/Footer';
import Header from '@components/common/fragments/Header';
import { GTMScripts } from '@components/common/scripts/GTM';
import type { ContentPageProps } from '@local-types/content/props';
import { arePropsNonEmpty } from '@utils/render';
import ErrorPage from '../../app/error';
import { ContentBody } from './ContentBody';

interface Props {
  contentPageProps: ContentPageProps;
  globalNavProps: GlobalNavigationProps;
  stage: Stage;
  shouldInjectGtmScript: boolean;
}

export default function ContentPage({
  contentPageProps,
  globalNavProps,
  stage,
  shouldInjectGtmScript,
}: Props) {
  const {
    dataLayer,
    globalHeadProps,
    smartBannerProps,
    firstTopTeaserImageProps,
    ...componentsProps
  } = contentPageProps;

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {/* head */}
      <GlobalHead {...globalHeadProps} />

      {/* scripts */}
      <GTMScripts dataLayer={dataLayer} stage={stage} withScripts={shouldInjectGtmScript} />

      {/* Top Section */}
      <LogoutBanner />
      {arePropsNonEmpty(smartBannerProps) && <SmartBanner {...smartBannerProps} />}

      <Header {...globalNavProps} />

      <CommonDisclaimer />

      <ContentBody {...componentsProps} />

      <Footer {...globalNavProps} />
    </ErrorBoundary>
  );
}
