/* eslint-disable max-lines */
import type {
  ImageSet,
  Modify,
  Nullable,
  PrismicFullResponsiveImage,
  PrismicLink,
  PrismicResponsiveImage,
} from 'corgi-types';
import type { Newsletter as DyNewsletter } from 'dynamic-yield-sdk';
import type {
  ContentFullContentImageSet,
  ContentImage,
  ContentLink,
  ContentTopBannerSlice as DyTopBanner,
  ContentTopBannerV2Slice as DyTopBannerV2,
  ShopInShopNavigation,
} from 'graphql-ts-types';

import type { ButtonType } from '../../enums/cta-button';
import type { NamedContentLink } from './content';
import type { Article, CtaHref } from './product-list';

export type PrismicLinkUrl = Pick<PrismicLink, 'url'>;

export enum SliceType {
  VisualNavigation = 'visual_navigation',
  TextBlock = 'text_block',
  TopBanner = 'top_banner',
  TopBannerV2 = 'top_banner_v2',
  DyTopBannerV2 = 'dy_top_banner_v2',
  CardGrid = 'card_grid',
  CardGridWithQuery = 'card_grid_with_query',
  UspTeaser = 'usp_teaser',
  ProductListWithSkus = 'product_list_with_skus',
  ProductListWithQuery = 'product_list_with_query',
  TwoThirdsPictureText = 'two_thirds_picture_text',
  HalfPictureText = 'half_picture_text',
  DoublePictureText = 'double_picture_text',
  ShopTheLook = 'shop_the_look',
  TabBar = 'tab_bar',
  VerticalSpacing = 'vertical_spacing',
  NewsletterSignup = 'newsletter_signup',
  CtaButton = 'cta_buttons',
  TopNewsSection = 'top_news_section',
  ArticlesNavigation = 'articles_navigation',
  ContentSlider = 'content_slider',
  VoucherPromotions = 'voucher_promotions',
  DYVoucherPromotions = 'dy_voucher_promotions',
  ArticleHeader = 'article_header',
  BrandsGlossary = 'brands_glossary',
  ShowroomOutletHeading = 'showroom_outlet_heading',
  TopTeaser = 'top_teaser',
  DyTopTeaserV2 = 'dy_top_teaser_v2',
  RecommendationSlider = 'recommendation_slider',
  InspirationalTeaser = 'inspirational_teaser',
  DyNewsletter = 'dy_newsletter',
  OfferTeaser = 'offer_teaser',
  OneTrustSettingsOverlay = 'onetrust_settings_overlay',
  DyVisualNavigation = 'dy_visual_navigation',
  DyListNavigation = 'dy_list_navigation',
  DyTopBanner = 'dy_top_banner',
  DyGlobalRibbon = 'dy_global_ribbon',
  ExternalScript = 'external_script',
  HomeClub = 'homeclub',
  CheckGiftCardBalance = 'check_gift_card_balance',
  ShopInShopV2 = 'shop_in_shop_data_v2',
}

interface BaseSlice {
  type: SliceType;
}

export interface TextBlock extends BaseSlice {
  content: string;
  isContentCenterAligned: boolean;
  isContentWithReducedWidth: boolean;
}

interface VisualNavigationItem {
  link: PrismicLinkUrl;
  description: string;
  image: PrismicResponsiveImage;
}

export interface VisualNavigation extends BaseSlice {
  title: string;
  seeAllLabel: string;
  seeAllLink: PrismicLinkUrl;
  items: VisualNavigationItem[];
}

type TopBanner = DyTopBanner & BaseSlice;

export type TopBannerV2 = DyTopBannerV2 & BaseSlice;

export interface CardGridItem {
  image: PrismicResponsiveImage;
  href: PrismicLinkUrl;
  headline?: string;
  subline?: string;
}

export interface CardGrid extends BaseSlice {
  title: string;
  items: CardGridItem[];
}

export type FullResponsiveImage = Modify<
  PrismicFullResponsiveImage,
  {
    forMobile: Nullable<ImageSet>;
    forTablet: Nullable<ImageSet>;
    forDesktop: Nullable<ImageSet>;
    forLargeDesktop: Nullable<ImageSet>;
  }
>;

export interface UspItem {
  extendedImageSet: PrismicResponsiveImage;
  fullImageSet: FullResponsiveImage;
  text: string;
}

export interface UspTeaser extends BaseSlice {
  items: UspItem[];
  title: string;
  subtitle?: string;
  ctaLinkLabel: string;
  ctaLinkUrl: PrismicLinkUrl;
  backgroundColor?: string;
  textColor?: string;
  isContentWithReducedWidth: boolean;
}

export interface ProductList extends BaseSlice {
  heading?: string;
  isHeadingCenterAligned?: boolean;
  ctaLabel?: string;
  ctaHref?: CtaHref;
  ctaType?: ButtonType;
  articles: Article[];
}

export interface PictureTextItem {
  image: Nullable<PrismicFullResponsiveImage>;
  description: string;
  ctaUrl: PrismicLinkUrl;
  ctaLabel: string;
  ctaLabelColor: string;
  ctaBackgroundColor: string;
  revert: boolean;
}

export interface PictureText extends BaseSlice {
  items: PictureTextItem[];
}

export interface ShopTheLook extends BaseSlice {
  items: Article[];
  title: string;
  image: Nullable<PrismicFullResponsiveImage>;
}

export interface ShopInShopV2 extends BaseSlice {
  data: {
    filterQuery?: string;
    shopName: string;
    logo: Pick<ContentImage, 'url' | 'alt'>;
    homepage: Nullable<Pick<ContentLink, 'fullUrl'>>;
    banner?: Nullable<ContentFullContentImageSet>;
    navigation: ShopInShopNavigation;
  };
}

interface TabBarItem {
  isActive: boolean;
  link?: PrismicLinkUrl;
  text: string;
}

export interface TabBar extends BaseSlice {
  activeTextColor?: string;
  activeBackgroundColor?: string;
  items: TabBarItem[];
}

type ButtonKind = 'primary' | 'secondary' | 'bare' | 'black' | 'green';

interface CtaElement {
  kind: ButtonKind;
  label: Nullable<string>;
  link: {
    url: string;
  };
  image: Nullable<{
    url: string;
    alt: string;
  }>;
}

export interface CtaButtons extends BaseSlice {
  items: CtaElement[];
}

type Newsletter = DyNewsletter & BaseSlice;

type VerticalSpacing = BaseSlice;

interface TopNewsSmallImage {
  image: Nullable<PrismicFullResponsiveImage>;
  headline: string;
  subtitle: string;
  url: PrismicLinkUrl;
}

export interface TopNewsSection extends BaseSlice {
  largeImage: Nullable<PrismicFullResponsiveImage>;
  headlineLargeImage: string;
  subtitleLargeImage: string;
  urlLargeImage: PrismicLinkUrl;
  headlineSmallImages: string;
  smallImages: TopNewsSmallImage[];
}

export interface ArticlesNavigation extends BaseSlice {
  prevArticle: Nullable<NamedContentLink>;
  nextArticle: Nullable<NamedContentLink>;
}

interface ContentSliderItem {
  headline: string;
  subtitle: string;
  url: PrismicLinkUrl;
  image: Nullable<PrismicFullResponsiveImage>;
}

export interface ContentSlider extends BaseSlice {
  titleText: string;
  seeAllText: string;
  seeAllLink: PrismicLinkUrl;
  slides: ContentSliderItem[];
}

interface VoucherPromotion {
  voucherImage: Nullable<PrismicFullResponsiveImage>;
  voucherHeadline: string;
  subheading: string;
  disclaimerLabel: string;
  disclaimerLabelColor: string;
  disclaimerText: string;
  ctaButtonLink: PrismicLinkUrl;
  ctaButtonLabel: string;
  ctaButtonType: Omit<ButtonKind, 'bare' | 'green'>;
  voucherCodeLabel: Nullable<string>;
  voucherCode: Nullable<string>;
  timerLabel: Nullable<string>;
  timerEndDate: Nullable<string>;
}

export interface VoucherPromotions extends BaseSlice {
  contentId?: string;
  name?: string;
  items: VoucherPromotion[];
}

export enum DyVoucherPromotionsSelector {
  VoucherPromo1 = 'Content VoucherPromo1',
  VoucherPromo2 = 'Content VoucherPromo2',
  VoucherPromo3 = 'Content VoucherPromo3',
  VoucherPromo4 = 'Content VoucherPromo4',
  VoucherPromo5 = 'Content VoucherPromo5',
}

export interface DYVoucherPromotions extends BaseSlice {
  selector: DyVoucherPromotionsSelector;
}

export interface ContentArticleHeader extends BaseSlice {
  author?: string;
  publishingDate?: string;
  category?: Nullable<string>;
  isHeaderLeftAligned: boolean;
  isHeaderWithReducedWidth: boolean;
  isCategoryHidden: boolean;
  isArticleInfoHidden: boolean;
  articleTitle: string;
  articleTitleColor?: Nullable<string>;
  readingTimeMinutes: number;
}

export interface BrandsGlossary extends BaseSlice {
  glossaryHeading: string;
}

export interface OpeningHours {
  openingDayFrom: string;
  openingDayTo: string;
  openingTime: string;
  closingTime: string;
}

export interface ShowroomOutletHeading extends BaseSlice {
  streetAddress: string;
  postalCode: string;
  addressLocality: string;
  openingHoursSpecification: OpeningHours[];
  phone: string;
  locationUrl: PrismicLink;
  instagramLabel: string;
  instagramLink: PrismicLink;
  extraInfo: Nullable<string>;
}

export type Placeholder = BaseSlice;

export interface RecommendationSlider extends BaseSlice {
  selector: string;
}

export type OneTrustSettingsOverlay = BaseSlice;

export type OfferTeaser = BaseSlice;

export type HomeClub = BaseSlice;

export interface TopTeaser extends BaseSlice {
  topTeaserSelector: number;
}

export type TopTeaserV2 = TopTeaser;

export interface DyVisualNavigation extends BaseSlice {
  selector: string;
}

export interface DyListNavigation extends BaseSlice {
  listNavigationSelector: string;
}

export enum ExternalScriptSelector {
  Butlers = 'Butlers',
  OneTrustSettingsOverlay = 'OneTrustSettingsOverlay',
}

export enum ContentPlaceholderSelector {
  ReferAFriend = 'ReferAFriend',
}

export interface ExternalScript extends BaseSlice {
  externalScriptSelector?: ExternalScriptSelector;
  contentPlaceholderSelector?: ContentPlaceholderSelector;
}

export type Slice =
  | TextBlock
  | VisualNavigation
  | TopBanner
  | TopBannerV2
  | CardGrid
  | UspTeaser
  | ProductList
  | PictureText
  | ShopTheLook
  | TabBar
  | VerticalSpacing
  | Newsletter
  | CtaButtons
  | TopNewsSection
  | ArticlesNavigation
  | ContentSlider
  | VoucherPromotions
  | DYVoucherPromotions
  | ContentArticleHeader
  | BrandsGlossary
  | ShowroomOutletHeading
  | Placeholder
  | RecommendationSlider
  | OneTrustSettingsOverlay
  | TopTeaser
  | TopTeaserV2
  | DyVisualNavigation
  | DyListNavigation
  | OfferTeaser
  | HomeClub
  | ExternalScript;
