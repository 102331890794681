import React from 'react';
import Script from 'next/script';

import type { PageAppDataLayer, Stage } from 'corgi-types';
import { useGTMConfig } from 'corgi/common/components/GTM';
import GtmNoScript from 'corgi/gtm-noscript/components/GtmNoscript';
import { getUpdatedInitialDataLayerScript } from 'frontend-utils';

import { trimScript } from '@utils/trim-script';

interface GTMScriptsProps {
  dataLayer: PageAppDataLayer;
  stage: Stage;
  withScripts: boolean;
  events?: object[];
}

export const GTMScripts = ({ dataLayer, stage, withScripts, events = [] }: GTMScriptsProps) => {
  const { src } = useGTMConfig(stage);

  return (
    <>
      <Script id="initial-datalayer">
        {trimScript(getUpdatedInitialDataLayerScript([dataLayer, ...events]))};
      </Script>
      {withScripts && (
        <>
          <Script src={src.global} />
          <GtmNoScript environment={stage} />
          <Script src={src.region} />
        </>
      )}
    </>
  );
};
