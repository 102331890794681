import React from 'react';

import Breadcrumbs from 'corgi/breadcrumbs/components/Breadcrumbs';
import NewsletterOverlay from 'corgi/newsletter-overlay/components/NewsletterOverlay';
import PopUpSection from 'corgi/pop-up/components/PopUp';

import type { ContentPageProps } from '@local-types/content/props';
import { composePropsWithLegacyMaxWidthDisabled } from '@utils/content/corgi';
import { arePropsNonEmpty } from '@utils/render';
import Slices from './fragments/Slices';

export const ContentBody = ({
  breadcrumbsProps,
  newsletterOverlayProps,
  popUpProps,
  slices,
}: Partial<ContentPageProps>) => (
  <>
    {arePropsNonEmpty(breadcrumbsProps) && (
      <Breadcrumbs {...composePropsWithLegacyMaxWidthDisabled(breadcrumbsProps)} />
    )}
    <Slices slices={slices} />
    {arePropsNonEmpty(newsletterOverlayProps) && <NewsletterOverlay {...newsletterOverlayProps} />}
    {arePropsNonEmpty(popUpProps) && <PopUpSection {...popUpProps} />}
  </>
);
