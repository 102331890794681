import { CorgiComponentName } from 'corgi-types';

import type { LayoutType } from '@local-types/content/layout';

export const DEFAULT_MAX_WIDTH = '1600px';

const gridStyles = {
  maxWidth: DEFAULT_MAX_WIDTH,
  mx: 'auto',
  width: 1,
};

// eslint-disable-next-line complexity
export const getSliceStyles = (type: CorgiComponentName | LayoutType): object => {
  switch (type) {
    case CorgiComponentName.ContentProductList:
      return {
        ...gridStyles,
        px: ['sp_8', 'sp_12', null, 'sp_16'],
      };
    case CorgiComponentName.TopBanner:
      return {
        ...gridStyles,
        flexDirection: 'column',
        display: 'flex',
      };
    case CorgiComponentName.CommonTabBar:
    case CorgiComponentName.CommonVisualNavigation:
    case CorgiComponentName.RecommendationsSlider:
    case CorgiComponentName.ArticlesNavigation:
    case CorgiComponentName.ContentSlider:
    case CorgiComponentName.VoucherPromotions:
    case CorgiComponentName.ContentArticleHeader:
    case CorgiComponentName.ShowroomOutletHeading:
      return {
        ...gridStyles,
        px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'],
      };
    case CorgiComponentName.SeoSection:
    case CorgiComponentName.NewsletterSignup:
      return { mb: ['sp_16', 'sp_24', null, 'sp_32'], width: 1 };
    case CorgiComponentName.BrandsGlossary:
      return {
        ...gridStyles,
        mt: ['sp_20', null, 'sp_24', 'sp_32'],
        px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'],
      };
    case CorgiComponentName.HomepageTopTeaser:
      return {
        ...gridStyles,
        pb: ['sp_24', '28px', 'sp_24'],
      };
    case CorgiComponentName.CommonInspirationalTeaser:
      return {
        ...gridStyles,
        px: ['sp_8', 'sp_24', 'sp_48', 'sp_64'],
      };
    case CorgiComponentName.OfferTeaser:
      return {
        ...gridStyles,
        px: ['sp_0', 'sp_24', 'sp_48', 'sp_64'],
      };
    default:
      return {
        mx: 'auto',
        width: 1,
      };
  }
};
