import React from 'react';

import type { LayoutConfig } from '@local-types/content/layout';
import { LayoutType } from '@local-types/content/layout';
import ShopTheLook from '../Layouts/ShopTheLook';
import VerticalSpacing from '../Layouts/VerticalSpacing';

const sliceLayoutMap = {
  [LayoutType.ShopTheLook]: ShopTheLook,
  [LayoutType.VerticalSpacing]: VerticalSpacing,
};

const LayoutSlice = ({ sliceConfig }: { sliceConfig: LayoutConfig }) => {
  const { layoutType, props } = sliceConfig;
  const LayoutComponent = sliceLayoutMap[layoutType];

  return LayoutComponent ? <LayoutComponent layoutProps={props} /> : null;
};

export default LayoutSlice;
