import { CorgiComponentName } from 'corgi-types';
import ArticlesNavigation from 'corgi/articles-navigation/components/ArticlesNavigation';
import BrandsGlossary from 'corgi/brands-glossary/components/BrandsGlossary';
import CardGrid from 'corgi/card-grid/components/CardGrid';
import CheckGiftCardBalanceForm from 'corgi/check-gift-card-balance-form/components/CheckGiftCardBalanceForm';
import CommonBanner from 'corgi/common-banner/components/CommonBanner';
import CommonInspirationalTeaser from 'corgi/common-inspirational-teaser/components/CommonInspirationalTeaser';
import CommonTabBar from 'corgi/common-tab-bar/components/CommonTabBar';
import CommonUspTeaser from 'corgi/common-usp-teaser/components/CommonUspTeaser';
import CommonVisualNavigation from 'corgi/common-visual-navigation/components/CommonVisualNavigation';
import { PageGridItem } from 'corgi/common/components/PageGrid';
import ContentArticleHeader from 'corgi/content-article-header/components/ContentArticleHeader';
import ContentProductList from 'corgi/content-product-list/components/ContentProductList';
import ContentSlider from 'corgi/content-slider/components/ContentSlider';
import CtaButtons from 'corgi/cta-buttons/components/CtaButtons';
import GlobalRibbon from 'corgi/global-ribbon/components/GlobalRibbon';
import HomeClubSection from 'corgi/home-club-section/components/HomeClubSection';
import HomepageTopTeaser from 'corgi/homepage-top-teaser/components/HomepageTopTeaser';
import ListNavigation from 'corgi/list-navigation/components/ListNavigation';
import NewsletterSignup from 'corgi/newsletter-signup/components/NewsletterSignup';
import OfferTeaser from 'corgi/offer-teaser/components/OfferTeaser';
import PictureText from 'corgi/picture-text/components/PictureText';
import RecommendationsSlider from 'corgi/recommendations-slider/components/RecommendationsSlider';
import SeoSection from 'corgi/seo-section/components/SeoSection';
import ShopInShopNavigation from 'corgi/shop-in-shop-navigation/components/ShopInShopNavigation';
import ShowroomOutletHeading from 'corgi/showroom-outlet-heading/components/ShowroomOutletHeading';
import TopBanner from 'corgi/top-banner/components/TopBanner';
import TopNewsSection from 'corgi/top-news-section/components/TopNewsSection';
import VoucherPromotions from 'corgi/voucher-promotions/components/VoucherPromotions';

import type { ComponentConfig } from '@local-types/content/layout';
import { getSliceStyles } from './styles';

const sliceComponentMap = {
  [CorgiComponentName.CommonVisualNavigation]: CommonVisualNavigation,
  [CorgiComponentName.SeoSection]: SeoSection,
  [CorgiComponentName.TopBanner]: TopBanner,
  [CorgiComponentName.CardGrid]: CardGrid,
  [CorgiComponentName.CommonUspTeaser]: CommonUspTeaser,
  [CorgiComponentName.ContentProductList]: ContentProductList,
  [CorgiComponentName.PictureText]: PictureText,
  [CorgiComponentName.CommonTabBar]: CommonTabBar,
  [CorgiComponentName.NewsletterSignup]: NewsletterSignup,
  [CorgiComponentName.CtaButtons]: CtaButtons,
  [CorgiComponentName.TopNewsSection]: TopNewsSection,
  [CorgiComponentName.ArticlesNavigation]: ArticlesNavigation,
  [CorgiComponentName.ContentSlider]: ContentSlider,
  [CorgiComponentName.VoucherPromotions]: VoucherPromotions,
  [CorgiComponentName.ContentArticleHeader]: ContentArticleHeader,
  [CorgiComponentName.BrandsGlossary]: BrandsGlossary,
  [CorgiComponentName.ShowroomOutletHeading]: ShowroomOutletHeading,
  [CorgiComponentName.HomepageTopTeaser]: HomepageTopTeaser,
  [CorgiComponentName.RecommendationsSlider]: RecommendationsSlider,
  [CorgiComponentName.CommonInspirationalTeaser]: CommonInspirationalTeaser,
  [CorgiComponentName.OfferTeaser]: OfferTeaser,
  [CorgiComponentName.ListNavigation]: ListNavigation,
  [CorgiComponentName.GlobalRibbon]: GlobalRibbon,
  [CorgiComponentName.HomeClubSection]: HomeClubSection,
  [CorgiComponentName.CheckGiftCardBalanceForm]: CheckGiftCardBalanceForm,
  [CorgiComponentName.ShopInShopNavigation]: ShopInShopNavigation,
  [CorgiComponentName.CommonBanner]: CommonBanner,
};

const ComponentSlice = ({ sliceConfig }: { sliceConfig: ComponentConfig }) => {
  const { corgiType, props } = sliceConfig;

  const CorgiComponent = sliceComponentMap[corgiType];
  const sliceStyles = getSliceStyles(corgiType);

  return (
    <PageGridItem mb={['sp_16', 'sp_20', 'sp_24', 'sp_32']} {...sliceStyles}>
      {CorgiComponent && <CorgiComponent {...props} />}
    </PageGridItem>
  );
};

export default ComponentSlice;
