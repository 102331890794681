import React from 'react';
import Script from 'next/script';

import { PageGridItem } from 'corgi/common/components/PageGrid';

import { DEFAULT_MAX_WIDTH } from '../Slices/styles';

const Butlers = () => (
  <PageGridItem
    width={1}
    maxWidth={DEFAULT_MAX_WIDTH}
    mx="auto"
    mb={['sp_16', 'sp_20', 'sp_24', 'sp_32']}
    px={['sp_16', 'sp_24', 'sp_48', 'sp_64']}
  >
    <div data-stockist-widget-tag="u8733" id="stockist-widget"></div>
    <Script id="butlers-external-link">
      {
        "(function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);})(document,'script','https://stockist.co/embed/v1/widget.min.js');"
      }
    </Script>
  </PageGridItem>
);

export default Butlers;
