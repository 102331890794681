import React from 'react';

import { PageGridItem } from 'corgi/common/components/PageGrid';
import ReferAFriendSection from 'corgi/refer-a-friend-section/components/ReferAFriendSection';

import type { ExternalScriptConfig } from '@local-types/content/layout';
import { ContentPlaceholderSelector, ExternalScriptSelector } from '@local-types/content/slice';
import Butlers from '../Layouts/Butlers';

const ScriptSlice = ({ sliceConfig }: { sliceConfig: ExternalScriptConfig }) => {
  const { props } = sliceConfig;
  const { externalScriptSelector, contentPlaceholderSelector, ...restProps } = props;

  if (externalScriptSelector === ExternalScriptSelector.Butlers) {
    return <Butlers />;
  }

  if (contentPlaceholderSelector === ContentPlaceholderSelector.ReferAFriend) {
    return (
      <PageGridItem>
        <ReferAFriendSection {...restProps} />
      </PageGridItem>
    );
  }

  return null;
};

export default ScriptSlice;
