import type { CorgiComponentName, MapOf } from 'corgi-types';

import type { ContentPlaceholderSelector, ExternalScriptSelector } from './slice';

export const enum LayoutType {
  ShopTheLook = 'shop_the_look',
  VerticalSpacing = 'vertical_spacing',
}

export type LayoutProps = MapOf<CorgiComponentName, object>;

export interface LayoutConfig {
  layoutType: LayoutType;
  props: LayoutProps;
}

export interface ComponentConfig {
  corgiType: CorgiComponentName;
  props: object;
}

export interface ExternalScriptConfig {
  externalScript: true;
  props: {
    externalScriptSelector?: ExternalScriptSelector;
    contentPlaceholderSelector?: ContentPlaceholderSelector;
  };
}

export type PageSliceConfig = ComponentConfig | LayoutConfig | ExternalScriptConfig;
